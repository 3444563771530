.main {
    margin: auto;
    letter-spacing: 0.3px;
  }
  
  .home-header {
    display: flex;
    align-items: center; 
    color: #E600A5;
    font-size: 0.9rem;
    font-weight: 400;
    padding-top: 0!important;
    height: 38px;
  }
  
  .header_logo {
    background-image: url("../images/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 38px;
    height: 38px;
    margin-left: 0.9rem;
  }
  
  .header_name,
  .header_right {
    margin-top:0.2em;
  }
  
  .header_right {
    margin-right: 1.4rem;
    margin-left:auto;
    display: flex;
  }
  
  .header_right span {
    padding:0.3em 0.4em;
  }
  .start_app {
    margin-top: 1px;
    color: white;
    background: linear-gradient(to bottom, rgba(230,0,166,1), rgba(151,0,204,0.48)); 
    border-radius: 0.6em;
    box-shadow: none;
    border: none;
    font-size: 0.7em;
    margin-left: 1.6em;
  }
  
  .banner {
    background-image: url(../images/header.png);
    background-repeat: no-repeat;
    background-size: cover;
    height: 335px;
  }
  .banner_title,
  .banner_content,
  .banner button{
    margin-left: 50px;
    color: white;
  }
  
  .banner_title {
    font-size: 2em;
    font-weight: 700;
    padding-top: 1.8em;
  
  }
  .banner_content {
    margin-top: 0.8em;
    font-size: 1.1em;
    font-weight: 500;
    letter-spacing: 0;
  }
  .banner button {
    margin-top: 1.4em;
    font-size: 1.2em;
    font-weight: 200;
    color: white;
    background: linear-gradient(to bottom, rgba(230,0,166,1), rgba(151,0,204,0.48)); 
    border-radius: 0.4em;
    padding: 0.2em 0.8em;
    box-shadow: none;
    border: none;
    letter-spacing: 0.6px;
  }
  .container {
    width: 100%;
    padding-bottom: 1em;
  }
  
  .introduction {
    display: flex;
    align-items: center; 
    justify-content: center;
    padding-top: 1.6em;
    padding-left: 1em;
  }
  
  .introduction_content1 .txt{
    max-width: 16em;
  }
  .introduction_content2 .txt {
    max-width: 15em;
  }
  .introduction_content3 .txt {
    max-width: 20em;
  }
  .introduction_seperator {
    width: 1px;
    margin-top: 0.4em;
    height: 4em;
    border-left: 1px solid #32323236;
    margin-left: 0.6em;
    margin-right: 1em;
  }
  .introduction .title {
    font-size: 1.4em;
    font-weight: 400;
  }
  .introduction .txt {
    padding-top: 0.4em;
    font-weight: 400;
    letter-spacing: 0.7px;
    font-size:0.8rem;
  } 
  
  .block {
    margin: auto;
    margin-top: 3.2em;
    text-align: center;
    width: 44rem;
  }
  
  .block_title {
    font-size: 1.5em;
    font-weight: 600;
  }
  .block_txt {
    letter-spacing: 0.6px;
    margin-top: 0.4em;
    max-width: 40em;
    margin: auto;
    padding-top: 1.4em;
    padding-bottom: 0.6em;
    font-size: 0.9rem;
    font-weight: 350;
    color:#555555;
  }
  
  .block_demand2_img,
  .block_demand1,
  .block_demand2_content {
    width: 40em;
    margin:auto;
  }
  .block_demand_txtExtra {
    max-width: 44em;
  }
  .block_demand_title {
    text-align: left;
    font-size: 1rem; 
    line-height: 1.6em;
  }
  .block_demand1 {
    display: flex;
    margin-top: 1em;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  .block_demand1_content {
    padding-bottom: 0.9em;
  }
  .block_demand1_content p {
    text-align: left;
    line-height: 80%;
    font-size: 0.8em;
    font-weight: 300;
  }
  .block_demand1_img {
    background-image: url("../images/demand1.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 16em;
    background-position-x: right;  
    margin-top: 0.4em;
  }
  
  .block_demand2_img {
    background-image: url("../images/combination.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-x: center;  
    height: 160px;
    margin-top: 1em;
  }

  .block_combo {
    display: flex;
    margin-top: 1em;
    padding-top: 1em;
  }

  .block_comb_txtExtra {
    max-width: 44em;
  }

  .block_combo_right {
    margin-left:auto;
  }
  .block_combo_img {
    background-image: url("../images/order.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 22em;
    height: 20em;
    background-position-y: center;
  }
  .block_combo_content {
    background-image: url("../images/order_right.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 20em;
    height: 18em;
    background-position-y: center;
    margin-top: 1em;
    margin-left: 1em;
  }
  
  .block_cons {
    margin-top: 2em;
  }

  .block_cons_part {
    display: flex;
    width: 100%;
    align-items: flex-end;
    margin-bottom: 1.4em;
  }
  .cons_part_left {
    text-align: left;
  }
  .cons_part_right {

  }
  .guard_img {
    background-image: url("../images/guard_eg.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 22em;
    height: 10em;
    background-position-y: center;
  }
  .progress_img {
    background-image: url("../images/progress_eg.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 14em;
    height: 10em;
    background-position-y: center;
    margin-left: 3em;
  }

  .service_img {
    background-image: url("../images/service_eg.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 13em;
    height: 10em;
    background-position-y: center;
    margin-left: 2em;
  }

  .modal_content {
    background-image: url("../images/modal.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: center;
  }
  .cons_title {
    color:#A6008B;
    font-weight: 600;
    margin-bottom: 0.4em;

  }
  .cons_subtitle {
    color:#A6008B;
    font-size: 0.9em;
    margin-bottom: 1em;
  }
  .cons_content {
    font-size: 0.8em;
    font-weight: 350;
  }
  .cons_content p {
    text-indent: -0.8rem;
    padding-left: 0.8em;
  }

  .block_permission {
    display: flex;
    font-size: 0.7rem;
    align-items: center ;
    margin-top: 1.8em;
    font-weight: 400;
    color: #E16F98;
    padding-left: 1em;
  }
  .block_permissionExtra {
    color: #8F7FDA;
    margin-top:1em;
    padding-bottom: 0.4em;
  }
  .block_permission_entity {
    line-height: 0.4em;
    min-width: 10em;
    border-radius: 1em;
    text-align: center;
    line-height: 0.3em;
  }
  .block_permission_entity_color1 {
    border: 1px solid #E16F98 ;
    font-size: 0.9em;
  }
  .block_permission_entity_color2 {
    color:#8F7FDA;
    border: 1px solid #8F7FDA;
    font-size: 0.9em;
  }
  .block_permission_perm {
    text-align: left;
    line-height: 0.6em;
    border-radius: 1em;
    padding-left: 1em;
    min-width: 33em;
    margin-left: 3em;
    padding-bottom: 0.3em;
    padding-top: 0.3em;
  }
  .block_permission_perm_color2 {
    background-color: rgba(143, 127, 218, 0.18);
    font-weight: 300;
  }
  .block_permission_perm_color1 {
    background-color: rgba(220,130,168, 0.07);
    font-weight: 300;
  }
  .welcome_content {
    margin-top: 2em;
    font-size: 0.8em;
    text-align: left;
    margin-bottom: 3em;
  }
  
  .plist:before {
    color:#A6008B;
    content:'・';
  }
  .footer {
    background-image: url("../images/footer.png");
    background-repeat: no-repeat;
    background-size: cover;
    height: 239px;
    margin-top: -1em;
  }
  .footer_icons {
    display: flex;
    padding-top: 16px;
    align-items: center; 
    justify-content: center;
  }
  .footer_icons_x {
    background-image: url("../images/x.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 3px;
    height: 26px;
    width: 26px;
  }
  .footer_icons_github {
    background-image: url("../images/github.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 28px;
    width: 28px;
    margin-left: 1.2em;
  }
  .footer_icons_discord {
    background-image: url("../images/discord.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 28px;
    width: 28px;
    margin-left: 1.2em;
  }

  .modal-x-icon {
    background-image: url("../images/x.svg");
    background-repeat: no-repeat;
    background-size: contain;
    margin-top: 3px;
    height: 20px;
    width: 20px;
    margin-left: 0.4em;
  }

  .modal-stay {
    display: flex;
    align-items: center;
  }
