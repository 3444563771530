
.GuardCustomNode  .react-flow__handle {
    width: 20px;
    height: 1px;
    border-radius: 5px;
    background-color: #E8A7D5;
  }
  
.GuardCustomNode  .react-flow__handle-top {
    top: -5px;
  }
  
.GuardCustomNode  .react-flow__handle-bottom {
    bottom: -5px;
  }
  
.GuardCustomNode  .react-flow__node {
    height: 40px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 2px;
    font-weight: 700;
    cursor: pointer;
  }