
.app-launch {
    letter-spacing: 0.03em;
}
.background1 {
    background-image: linear-gradient(to bottom, #F2E3F0, rgba(229, 212, 225, 0.18));
}

.Block {
    padding: 1em;
    margin-top: 1em;
    margin-bottom: 2em;
}

.infoTxt {
    color: #A6008B;
    font-style: italic;
    font-weight: bold;
}

.t1 {
    color: #A6008B;
    margin-bottom: 0.2em;
}
.t2 {
    margin-top: 0.2em;
    padding-left: 0.4em;
    line-height: 1.6em;
    margin-bottom: 1.6em;
}

.t3 {
    font-weight: 600;
    font-size: 1.1em;   
    margin-bottom: 0.2em;
}
.t4 {
    margin-top: 0.2em;
    margin-bottom: 0.2em;
    letter-spacing: 0.06em;
    font-weight: 350;
}

.t5 {
    margin-top: 2em;
    text-align: right;
    font-weight: 400;
    margin-bottom: 0;
    color:#A6008B;
    cursor: pointer;
    display: flex;
    justify-content: end;
    align-items: center;
    padding-bottom: 1em;
}

.t6 {
    margin-top: 2em;
    text-align: left;
    font-weight: 400;
    margin-bottom: 0;
    color:#A6008B;
    cursor: pointer;
    display: flex;
    justify-content: start;
    align-items: center;
    padding-bottom: 1em;
}

.firstword {
    color:rgb(35, 35, 35);
    font-weight: bold; 
}

.titleTxt {
    font-size: 1.4em;
    font-weight: 400;
}

.titleTxt2 {
    font-size: 1.2em;
    font-weight: 400;
}


.Demand-content {
    display: flex;
    font-size: 1.2em;
    margin-top: 1em;
}

.Demand-right {
    background-image: url("../images/launch_demand.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 26em;
    margin-left: 2em;
}
.Demand-eg-title {
    font-size: 1.1em;
}
.Demand-eg-content {
    line-height: 90%;
}

.point-txt {
    padding-top: 1.4em;
}

.Demand-left-eg {
    padding-left: 1em;
    min-height: max-content;
    padding-right: 1em;
    display: inline-block;
    background-color: rgba(194,128,255,0.04);
}

.Reward-content {
    display: flex;
}

.egBkgnd {
    background-color: #e8a7d51a;
    font-size: 1rem;
    font-weight: 400;
    padding-top: 0.4em;
    padding-bottom: 0.4em;
    margin-top: 1em;
}

.Command {
    margin-top: 2em;
}

.Wrapper {
    display: flex;
}


.Reward {
    display: flex;
    margin-top: 1em;
}

.Reward-content1-img {
    background-image: url("../images/launch-reward.svg");
    background-repeat: no-repeat;
    background-size: contain;
    height: 128px;
    width: 128px;
    margin-left: 1.2em;
}


.Service-Content-Img {
    background-image: url("../images/order_eg.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 27em;
    width: 28em;
    margin-left: 1em;
    margin-right: -1em;
    margin-top: 1em;
}

.Machine-Content-Img {
    background-image: url("../images/machine_eg.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 44%;
    margin-left: 1em;
    margin-top: .6em;
}

.Guard-Content {
    display: flex;
    justify-content: center;
    text-align: center;
    justify-content: space-between; /* 控制主轴方向上的间隔 */
    align-items: center; /* 控制交叉轴方向上的对齐，这里用来间隔 */
    margin-top: 2em;
}

.Guard-Content > div:not(:last-child) {
    margin-right: 2em;
}

.Guard-Content1-img {
    background-image: url("../images/guard-eg1.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 100%;
    background-position: center center;
}

.Guard-Content2-img {
    background-image: url("../images/guard-eg2.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 100%;
    background-position: center center;
}

.Guard-Content3-img {
    background-image: url("../images/guard-eg3.png");
    background-repeat: no-repeat;
    background-size: contain;
    height: 60px;
    width: 100%;
    background-position: center center;
}

.Wrapper2 {
    margin-bottom: 1.4em;
    display: flex;
}

.Wrapper2 > div:not(:last-child) {
    margin-right: 2.2em; 
}

  
.common_object {
    width: 50%;
    padding:1em;
    padding-bottom: 0.2em;
    background-color: rgb(230 193 219 / 28%);
    font-size: 1.1em;
    font-weight: 350;
    border-radius: 16px;
    line-height: 1.6em;
}

.fullwidth_object {
    width: 100%;
    padding:1em;
    padding-bottom: 0.2em;
    background-color: rgb(230 193 219 / 28%);
    font-size: 1.1em;
    font-weight: 350;
    border-radius: 16px;
    line-height: 1.6em;
}