html, body {
  width: 100%;
  margin: 0;
  padding: 0;
}

body {
  margin: 0 auto;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI',  'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgba(247,237,241, 0.5);
  padding:0;
  margin:0 auto;
  border:none;
  width: calc(100vw - (100vw - 100%));
}

#root {
  padding:0;
  margin:0 auto;
  border:none;
  width: 980px;
  transform-origin: 490px 0px;
  transition: transform 0.3s ease; /* 添加过渡效果 */
  overflow: hidden;
  --primary-color:#E8A7D5;
  z-index: 0;
}

#root::before {
  position: absolute;
  inset: 0;
  z-index: -1;
  content: "";
  background-size:cover;
  mix-blend-mode: screen;
  transform: scale(1.1), rotate(17deg);
  background: radial-gradient(ellipse farthest-side at center, #E7C3DA, #F6ECF0 );
}
