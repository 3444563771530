.cmdButton {
    border: 1px solid #A6008B;
    border-radius: 1.6rem;
    color: #A6008B;
    padding: 0.8rem 1.6rem;
    text-transform: none;
    font-size: 1.1rem;
    font-weight: 600;
    background-color: transparent;
    cursor: pointer;
}
.cmdButton:hover {
    background: linear-gradient(to bottom, #E600A6, rgba(151, 0, 204, 0.48));
    border: 1px solid #A6008B;
    color: white;
}

.trans270 {
    transform: rotateZ(270deg);
}
.trans45 {
    transform: rotateZ(45deg);
    -webkit-transform: rotate( 45deg);
    transform-origin: bottom left;
}

.truncate-3-lines {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 1.1em;
}

.notfound {
    background-image: url("../images/404.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 6em;
    height: 6em;
}

.edgeObject {
    z-index: 300;
  } 
  
  .popAddress {
    position: absolute;
    z-index: 9999!important;
    pointer-events:none!important;
  }
  .popAddress > * {
    pointer-events: auto!important;
  }

  .popEdg {
    position: absolute;
    pointer-events:none!important;
  }
  .popEdg > * {
    pointer-events: auto!important;
  }
  
.noboxshadow {
    box-shadow: none!important;
}
.noboxshadow * {
    box-shadow: none!important;
}
.littlePaddingTopButton {
    padding-top: 0;
    padding-bottom: 0;
}
.cmdText {
    text-decoration: none;
    cursor: pointer;
}
.cmdText:hover {
    color:#A6008B!important;
}
.cmdText:link {
    color: black;
}
.cmdText:visited {
    color: black;
}

.flexline {
    flex-grow: 0;
    flex-shrink: 1;
    flex-basis: 100%; /* 或者根据需求设置其他值 */
}

@keyframes scroll {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-100%);
    }
}

.noneScroll::-webkit-scrollbar {
    display: none;
    width: 0!important;
}
.noneScroll {
    overflow: -moz-scrollbars-none; 
    -ms-overflow-style: none;
    scrollbar-color: transparent transparent; 
}

.mtTableBug {
    box-shadow: none!important;
}

.mtTableBug * {
    box-shadow: none!important;
}
.mtTableBug  .MuiTablePagination-toolbar {
    padding:0!important;
}