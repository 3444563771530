

.InitNode .react-flow__handle-top {
    background-color: #E8A7D5;
    width: 0.5em;
    height: 0.5em;
    line-height: 0.5em;
    top:0px;
    border: none;
}
.InitNode .react-flow__handle-bottom {
    background-color: #E8A7D5;
    width: 0.5em;
    height: 0.5em;
    line-height: 0.5em;
    bottom:0px;
    border: none;
}

.CustomNode  .react-flow__handle {
    width: 20px;
    height: 1px;
    border-radius: 5px;
    background-color: #E8A7D5;
  }
  
.CustomNode  .react-flow__handle-top {
    top: -5px;
  }
  
.CustomNode  .react-flow__handle-bottom {
    bottom: -5px;
  }
  
.CustomNode  .react-flow__node {
    height: 40px;
    width: 150px;
    justify-content: center;
    align-items: center;
    display: flex;
    border-width: 2px;
    font-weight: 700;
  }
  

/*
.react-flow__edge path,
  .react-flow__connectionline path {
    stroke-width: 20;
    stroke-opacity: 0;
    marker-end: none;
    marker-start: none;
  }
  
 .wrapper {
    flex-grow: 1;
    height: 100%;
  }
  */