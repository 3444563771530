
.app-header {
    height: 64px;
    padding-top: 0.4em;
    display: flex;
    align-items: center;
  }
  
  .logo {
    background-image: url("../images/logo.png");
    background-repeat: no-repeat;
    background-size: cover;
    width: 38px;
    height: 38px;
    margin-left: 0.8rem;
    cursor: pointer;
  }
  
  .wallet {
    margin-left: auto;
    margin-right: 0.8rem;
    width: 38px;
    height: 38px;
    padding: 2px;
  }
  #hidden-right {
    width: 1px;
    height: calc(100% + 3px);
    visibility: hidden;
  }

  .focus-button {
    text-transform: none!important;
    margin-left: 0.3em!important;
    font-size: 1.1em!important;
    color: #A6008B!important;
  }

  .unfocus-button {
    text-transform: none !important;
    margin-left: 0.3em!important;
    font-size: 1em!important;
    color: #E8A7D5!important;
  }